import { RawImportedDataRequestDto, ScenarioAdFormatResponseDto } from '@api-clients/media-plan';
import { AsyncCard, Loading } from '@components/atoms';
import { useAppContextHelper } from '@hooks/_contexts';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '@redux';
import { useApiConfiguration } from '@hooks/configuration';
import {
    proveCampaignIsError,
    proveCampaignIsPending,
    proveSelectedCampaign,
} from '@redux/slices/prove/campaign/selectors';
import { ErrorCard } from '@components/molecules/errorCard/ErrorCard';
import {
    proveAdFormatIsError,
    proveAdFormatIsPending,
    proveAvailableAdFormats,
} from '@redux/slices/prove/adFormat/selectors';
import { globalCampaignIsPending, selectedGlobalCampaign } from '@redux/slices/global/campaign/selectors';
import {
    proveAvailableLineItems,
    proveLineItemIsError,
    proveLineItemIsPending,
} from '@redux/slices/prove/lineItem/selectors';
import {
    ExpandableBanner,
    ExpandableBannerBody,
    ExpandableBannerHeader,
} from '@components/atoms/expandableBanner/ExpandableBanner';
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Input,
    Select,
    Spacer,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { GlobalCampaignHeaderSection } from '@components/organisms';
import { CloseIcon, DownloadIcon, WarningIcon } from '@chakra-ui/icons';
import { ProveAdFormat } from '@api-clients/prove/schema/ProveAdFormat';
import { ArrowDownTrayIcon, PlusIcon } from '@heroicons/react/24/solid';
import { ProveLineItemsTable } from '@components/organisms/prove/ProveLineItemsTable';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ImportedProveLineItem, ProveLineItem } from '@api-clients/prove/schema/ProveLineItem';
import moment from 'moment';
import { routing } from '@configs';
import { ImportFileModal } from '@components/molecules';
import { mimeType } from '@components/atoms/fileUploadContainer/FileUploadContainer';
import AttentionProveImportHelper from '@components/atoms/fileUploadContainer/AttentionProveImportHelper';
import { createAllProveLineItemsAsync } from '@redux/slices/prove/lineItem/thunks';
import { usePosthogEvent } from '@hooks/_contexts/app/usePosthog';

export type SubTableDataType = ScenarioAdFormatResponseDto & { budget: number };
export type TableDataType = RawImportedDataRequestDto & {
    isChecked: boolean;
    adFormats?: SubTableDataType[];
};

interface FormData {
    name: string;
    mediaType: string;
    adFormat: string;
}

export const ProveManagementPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { campaignId } = useParams();
    const { getProveManagementServiceConfig, getProveTagServiceConfig } = useApiConfiguration();
    const selectedGlobalCampaignSelector = useSelector(selectedGlobalCampaign);
    const availableAdFormatsSelector = useSelector(proveAvailableAdFormats);
    const proveSelectedCampaignSelector = useSelector(proveSelectedCampaign);
    const globalCampaignIsPendingSelector = useSelector(globalCampaignIsPending);
    const proveCampaignIsPendingSelector = useSelector(proveCampaignIsPending);
    const proveCampaignIsErrorSelector = useSelector(proveCampaignIsError);
    const proveLineItemIsPendingSelector = useSelector(proveLineItemIsPending);
    const proveLineItemIsErrorSelector = useSelector(proveLineItemIsError);
    const availableLineItemsSelector = useSelector(proveAvailableLineItems);
    const adFormatIsPendingSelector = useSelector(proveAdFormatIsPending);
    const adFormatIsErrorSelector = useSelector(proveAdFormatIsError);
    const [supportedAdFormats, setSupportedAdFormats] = useState([] as Array<ProveAdFormat>);
    const {
        currentContextValues: { organisation },
    } = useAppContextHelper();
    const {
        helper: {
            selectGlobalCampaign,
            selectProveCampaign,
            retrieveProveAdFormats,
            retrieveProveLineItems,
            resolveProveAdFormatName,
        },
    } = useAppContextHelper();

    const { t } = useTranslation('prove');
    const content = t('prove.lineItems', {
        returnObjects: true,
    });

    const [lineItemsToCreate, setLineItemsToCreate] = useState([] as Array<ProveLineItem>);
    const [selectedLineItemToCreate, setSelectedLineItemToCreate] = useState<
        ProveLineItem | undefined
    >();
    const [importedFile, setImportedFile] = useState<File | undefined>();
    const [importedLineItems, setImportedLineItems] = useState([] as Array<ImportedProveLineItem>);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!campaignId) {
            return;
        }

        selectGlobalCampaign(campaignId).catch();
        selectProveCampaign(campaignId).catch();
        retrieveProveAdFormats().catch();
    }, []);

    const {
        handleSubmit,
        reset,
        register,
        setValue,
        formState: { errors, isValid },
    } = useForm<FormData>({
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const {
        isOpen: isFileDropModalOpen,
        onClose: onFileDropModalClose,
        onOpen: onFileDropModalOpen,
    } = useDisclosure();

    useEffect(() => {
        if (!proveSelectedCampaignSelector) {
            return;
        }

        retrieveProveLineItems(proveSelectedCampaignSelector).catch();
    }, [proveSelectedCampaignSelector]);

    const onLineItemToCreate = async (data: FormData) => {
        const lineItems = [];

        const newLineItem = {
            name: data.name,
            adFormat: data.adFormat,
        };

        let hasReplacedInvalidLineItem = false;

        for (let i = 0; i < lineItemsToCreate.length; i++) {
            const lineItem = lineItemsToCreate[i];
            if (selectedLineItemToCreate && selectedLineItemToCreate.name === lineItem.name) {
                lineItems.push(newLineItem);

                hasReplacedInvalidLineItem = true;
            } else {
                lineItems.push(lineItem);
            }
        }

        if (!hasReplacedInvalidLineItem) {
            lineItems.push(newLineItem);
        }

        setLineItemsToCreate(lineItems);
        setSelectedLineItemToCreate(undefined);

        reset();
    };

    const isUniqueName = (value: string): boolean => {
        const allLineItems = lineItemsToCreate.filter((entry) => {
            return (
                selectedLineItemToCreate === undefined ||
                selectedLineItemToCreate.name !== entry.name
            );
        });

        if (availableLineItemsSelector) {
            allLineItems.push(...availableLineItemsSelector!);
        }

        for (let i = 0; i < allLineItems.length; i++) {
            if (allLineItems[i].name.trim().toLowerCase() === value.trim().toLowerCase()) {
                return false;
            }
        }

        return true;
    };

    const downloadTags = () => {
        let csvContent = 'data:text/csv;charset=utf-8,';

        csvContent += `"${content.existing.csv.headers.name}","${content.existing.csv.headers.adFormat}","${content.existing.csv.headers.tag}"\r\n`;

        const csvFormattedLineItems = availableLineItemsSelector!
            .filter((lineItem) => {
                return lineItem.status === 'READY' && lineItem.tag;
            })
            .map((lineItem) => {
                return `"${lineItem.name}","${resolveProveAdFormatName(
                    availableAdFormatsSelector!,
                    lineItem.adFormat!,
                )}","${lineItem.tag!.replace(/"/g, '""')}"\r\n`;
            });

        for (let i = 0; i < csvFormattedLineItems.length; i++) {
            csvContent += csvFormattedLineItems[i];
        }

        const encodedUri = encodeURI(csvContent);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute(
            'download',
            `${selectedGlobalCampaignSelector!.name} ${content.existing.csv.fileSuffix}.csv`,
        );
        document.body.appendChild(link);

        link.click();
    };

    function hasCampaignStarted() {
        const startDate = moment.utc(selectedGlobalCampaignSelector!.startDate);

        return startDate.isBefore(moment());
    }

    const acceptedFileTypes = [mimeType.xls, mimeType.xlsx];

    const onFileDropModalConfirm = () => {
        if (importedFile) {
            AttentionProveImportHelper.processFile(importedFile).then((lineItems) => {
                setImportedLineItems(lineItems);
            });
        } else {
            setImportedLineItems([]);
        }
    };

    const resolveAdFormatCode = (value?: string) => {
        if (value) {
            for (let i = 0; i < availableAdFormatsSelector!.length; i++) {
                const adFormat = availableAdFormatsSelector![i];

                if (adFormat.name.trim().toLowerCase() === value.trim().toLowerCase()) {
                    return adFormat.code;
                }
            }
        }

        return undefined;
    };

    const isValidLineItem = (lineItem: ProveLineItem): boolean => {
        const isValidName =
            lineItem.name !== undefined && lineItem.name.length > 0 && lineItem.name.length <= 100;
        const isAdFormatPopulated = lineItem.adFormat !== undefined;

        return isValidName && isAdFormatPopulated;
    };

    useEffect(() => {
        if (importedLineItems.length === 0) {
            return;
        }

        if (lineItemsToCreate.length > 0) {
            setLineItemsToCreate([]);

            return;
        }

        const processedLineItems = [] as Array<ProveLineItem>;

        for (let i = 0; i < importedLineItems.length; i++) {
            const importedLineItem = importedLineItems[i];
            if (isUniqueName(importedLineItem.name)) {
                processedLineItems.push({
                    name: importedLineItem.name,
                    // ad format is imported as the name (not the code) so this needs to be overridden
                    adFormat: resolveAdFormatCode(importedLineItem.adFormatName),
                });
            }
        }

        setImportedLineItems([]);
        setSelectedLineItemToCreate(undefined);
        setLineItemsToCreate(processedLineItems);
    }, [importedLineItems, lineItemsToCreate]);

    useEffect(() => {
        if (selectedLineItemToCreate) {
            setValue('name', selectedLineItemToCreate.name, { shouldValidate: true });
        }
    }, [selectedLineItemToCreate]);

    const onFileUpload = (files: Array<File>): void => {
        if (files.length > 0) {
            setImportedFile(files[0]);
        } else {
            setImportedFile(undefined);
        }
    };

    const emitAddLineItemPosthogEvent = usePosthogEvent('Add a line item');
    const emitCreateLineItemsPosthogEvent = usePosthogEvent('Create line items');

    return !proveCampaignIsErrorSelector &&
    !proveLineItemIsErrorSelector &&
    !adFormatIsErrorSelector &&
    organisation?.users ? (
        <>
            {!globalCampaignIsPendingSelector &&
            !proveCampaignIsPendingSelector &&
            proveSelectedCampaignSelector &&
            selectedGlobalCampaignSelector &&
            organisation.users ? (
                <>
                    <ImportFileModal
                        onClose={onFileDropModalClose}
                        onConfirm={onFileDropModalConfirm}
                        isOpen={isFileDropModalOpen}
                        templateUrl="/attentionPROVE_Template.xlsx"
                        templateType="attentionPROVE"
                        tabBannerHeadingText="Fill your line items into our template and upload it here"
                        tabBannerSubheadingText="Template updated on 9 September 2024"
                        tabBannerBodyText="Importing from a file will override your line items that haven’t been saved. Please review carefully before clicking confirm."
                        acceptedFileTypes={acceptedFileTypes}
                        onFileUpload={onFileUpload}
                    />
                    <ExpandableBanner>
                        <ExpandableBannerHeader>
                            <Container ref={containerRef}>
                                <HStack>
                                    <GlobalCampaignHeaderSection
                                        campaign={selectedGlobalCampaignSelector}
                                        allowEdits={false}
                                    />
                                    <Spacer />
                                    <Button
                                        rightIcon={<DownloadIcon />}
                                        colorScheme="orange"
                                        onClick={() => {
                                            downloadTags();
                                        }}
                                    >
                                        {content.existing.buttons.downloadTags}
                                    </Button>
                                    <Tooltip
                                        isDisabled={hasCampaignStarted()}
                                        label="Dashboard is disabled until the campaign has started"
                                    >
                                        <Button
                                            colorScheme="green"
                                            isDisabled={!hasCampaignStarted()}
                                            onClick={() =>
                                                navigate(
                                                    `/${routing.prove.root.path}/${selectedGlobalCampaignSelector.id}/${routing.prove.dashboard.path}`,
                                                )
                                            }
                                        >
                                            {content.existing.buttons.viewDashboard}
                                        </Button>
                                    </Tooltip>
                                </HStack>
                            </Container>
                        </ExpandableBannerHeader>
                        <ExpandableBannerBody autoOpen defaultIsOpen>
                            <Container>
                                <AsyncCard
                                    isLoading={
                                        proveLineItemIsPendingSelector || adFormatIsPendingSelector
                                    }
                                >
                                    <Flex>
                                        <Box>
                                            <Heading size="md" mb={2}>
                                                {content.new.title}
                                            </Heading>
                                            <Text mb={4}>{content.new.subtitle}</Text>
                                        </Box>
                                        <Spacer />
                                        <Button
                                            variant="outline"
                                            onClick={() => onFileDropModalOpen()}
                                            display="flex"
                                            alignItems="center"
                                            gap="0.5rem"
                                        >
                                            <Icon
                                                as={ArrowDownTrayIcon}
                                                boxSize="1.2em"
                                                strokeWidth="2px"
                                            />
                                            Import from file
                                        </Button>
                                    </Flex>
                                    <form onSubmit={handleSubmit(onLineItemToCreate)}>
                                        <Grid
                                            templateColumns="1fr 250px 250px 125px"
                                            width="100%"
                                            gap="1rem"
                                        >
                                            <GridItem>
                                                <FormControl
                                                    isRequired
                                                    isInvalid={errors.name !== undefined}
                                                >
                                                    <FormLabel>
                                                        {content.new.form.name.label}
                                                    </FormLabel>
                                                    <Input
                                                        {...register('name', {
                                                            required: {
                                                                value: true,
                                                                message:
                                                                content.new.form.name
                                                                    .requiredMessage,
                                                            },
                                                            validate: (value) =>
                                                                isUniqueName(value)
                                                                    ? true
                                                                    : content.new.form.name
                                                                        .duplicateMessage,
                                                            maxLength: {
                                                                value: 100,
                                                                message:
                                                                content.new.form.name
                                                                    .exceedsMaxLengthMessage,
                                                            },
                                                        })}
                                                    />
                                                    <FormErrorMessage>
                                                        {errors.name &&
                                                            (errors.name.message as string)}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem>
                                                <FormControl isRequired>
                                                    <FormLabel>
                                                        {content.new.form.mediaType.label}
                                                    </FormLabel>
                                                    <Select
                                                        placeholder={
                                                            content.new.form.mediaType.placeholder
                                                        }
                                                        {...register('mediaType', {
                                                            required: true,
                                                        })}
                                                        onChange={(event) => {
                                                            const selectedMediaType =
                                                                event.target.value;

                                                            const adFormats =
                                                                [] as Array<ProveAdFormat>;
                                                            for (
                                                                let i = 0;
                                                                i <
                                                                availableAdFormatsSelector!.length;
                                                                i++
                                                            ) {
                                                                if (
                                                                    availableAdFormatsSelector![i]
                                                                        .mediaType ===
                                                                    selectedMediaType
                                                                ) {
                                                                    adFormats.push(
                                                                        availableAdFormatsSelector![
                                                                            i
                                                                            ],
                                                                    );
                                                                }
                                                            }

                                                            setSupportedAdFormats(adFormats);
                                                        }}
                                                    >
                                                        <option value="display">
                                                            {content.new.form.mediaType.display}
                                                        </option>
                                                        <option value="video">
                                                            {content.new.form.mediaType.video}
                                                        </option>
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem>
                                                <FormControl isRequired>
                                                    <FormLabel>
                                                        {content.new.form.adFormat.label}
                                                    </FormLabel>
                                                    <Select
                                                        placeholder={
                                                            content.new.form.adFormat.placeholder
                                                        }
                                                        {...register('adFormat', {
                                                            required: true,
                                                        })}
                                                    >
                                                        {supportedAdFormats.map((item) => {
                                                            return (
                                                                <option
                                                                    key={item.code}
                                                                    value={item.code}
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem>
                                                <VStack height="100%">
                                                    <HStack>
                                                        <Button
                                                            mt={8}
                                                            onClick={() => {
                                                                emitAddLineItemPosthogEvent();
                                                                containerRef.current?.scrollIntoView(
                                                                    {
                                                                        behavior: 'smooth',
                                                                        block: 'start',
                                                                    },
                                                                );
                                                            }}
                                                            isDisabled={!isValid}
                                                            type="submit"
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <Icon as={PlusIcon} />
                                                        </Button>
                                                        <Button
                                                            mt={8}
                                                            onClick={async () => {
                                                                emitCreateLineItemsPosthogEvent();
                                                                containerRef.current?.scrollIntoView(
                                                                    {
                                                                        behavior: 'smooth',
                                                                        block: 'start',
                                                                    },
                                                                );

                                                                const managementConfiguration =
                                                                    await getProveManagementServiceConfig();
                                                                const tagConfiguration =
                                                                    await getProveTagServiceConfig();

                                                                dispatch(
                                                                    createAllProveLineItemsAsync({
                                                                        managementConfiguration,
                                                                        tagConfiguration,
                                                                        campaign:
                                                                            proveSelectedCampaignSelector!,
                                                                        lineItems:
                                                                            lineItemsToCreate!.filter(
                                                                                (entry) =>
                                                                                    isValidLineItem(
                                                                                        entry,
                                                                                    ),
                                                                            ),
                                                                    }),
                                                                );

                                                                reset();

                                                                setSelectedLineItemToCreate(
                                                                    undefined,
                                                                );
                                                                setLineItemsToCreate([]);
                                                            }}
                                                            isDisabled={
                                                                lineItemsToCreate.length === 0
                                                            }
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            Save
                                                        </Button>
                                                    </HStack>
                                                </VStack>
                                            </GridItem>
                                        </Grid>
                                    </form>
                                    <Box mt={4}>
                                        <Wrap>
                                            {lineItemsToCreate.map((entry) => {
                                                if (isValidLineItem(entry)) {
                                                    return (
                                                        <Button
                                                            key={entry.name}
                                                            size="sm"
                                                            rightIcon={
                                                                <Icon
                                                                    as={CloseIcon}
                                                                    boxSize="0.5rem"
                                                                    onClick={() => {
                                                                        setLineItemsToCreate(
                                                                            lineItemsToCreate.filter(
                                                                                (
                                                                                    lineItemToCreate,
                                                                                ) => {
                                                                                    return (
                                                                                        lineItemToCreate.name !==
                                                                                        entry.name
                                                                                    );
                                                                                },
                                                                            ),
                                                                        );
                                                                    }}
                                                                />
                                                            }
                                                            type="button"
                                                            colorScheme="gray"
                                                            display="flex"
                                                            alignItems="center"
                                                            onClick={() => {
                                                                setSelectedLineItemToCreate(entry);
                                                            }}
                                                        >
                                                            {entry.name} -{' '}
                                                            {resolveProveAdFormatName(
                                                                availableAdFormatsSelector!,
                                                                entry.adFormat!,
                                                            )}
                                                        </Button>
                                                    );
                                                }

                                                return (
                                                    <Button
                                                        key={entry.name}
                                                        size="sm"
                                                        rightIcon={
                                                            <Icon
                                                                as={WarningIcon}
                                                                boxSize="0.725rem"
                                                            />
                                                        }
                                                        type="button"
                                                        colorScheme="red"
                                                        display="flex"
                                                        alignItems="center"
                                                        onClick={() => {
                                                            setSelectedLineItemToCreate(entry);
                                                        }}
                                                    >
                                                        {entry.name}
                                                    </Button>
                                                );
                                            })}
                                        </Wrap>
                                    </Box>
                                </AsyncCard>
                            </Container>
                        </ExpandableBannerBody>
                    </ExpandableBanner>

                    <Container>
                        <AsyncCard isLoading={proveLineItemIsPendingSelector} my="1.5rem">
                            <Heading size="md" mb={2}>
                                {content.existing.title}
                            </Heading>
                            <Text mb={4}>{content.existing.subtitle}</Text>
                            {availableLineItemsSelector && availableAdFormatsSelector && (
                                <ProveLineItemsTable
                                    selectedCampaign={proveSelectedCampaignSelector}
                                    availableAdFormats={availableAdFormatsSelector}
                                    lineItems={availableLineItemsSelector}
                                    users={organisation.users}
                                />
                            )}
                        </AsyncCard>
                    </Container>
                </>
            ) : (
                <Loading />
            )}
        </>
    ) : (
        <ErrorCard />
    );
};
